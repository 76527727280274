<template>
  <div class="bg-default">
    <header class="header">
      <div class="header-left">
        <a href="#">
          <img
            class="header-settings-icon"
            src="img/settings-icon.png"
            alt="Settings"
          />
        </a>
      </div>
      <div class="header-center justify-end">
        <div class="header-card">
          <div class="header-card-text">
            hátralévő<br />
            idő
          </div>
          <market-timer
            @roundEnded="roundEnd()"
            :startTime="{
              time: this.$config.settings.difficultyWaitingTime,
              option: 'round',
            }"
          ></market-timer>
        </div>
      </div>
      <div class="header-right">
        <a @click="exit()">
          <img
            class="header-settings-icon"
            src="img/exit-icon.png"
            alt="Exit"
          />
        </a>
      </div>
    </header>
    <main class="main">
      <div class="container">
        <div class="card card-centerred flex-0">
          <div class="card-subtitle">Kedves {{nickname}}!</div>
        </div>
        <div class="card card-centerred">
          <div class="card-info mt-64 mb-64">
            <p class="mb-48">Üdvözlünk a Pénzmúzeum játék 4. állomásánál!</p>
            <p class="mb-32">
              Kipróbálhatod a tőzsde világát és a részvénykereskedést. A
              feladatod az lesz, hogy felismerd, milyen cégekkel érdemes
              kereskedni. Amennyiben jól gondolkodsz, ajándékboltban
              felhasználható tallérokat kapsz jutalomként!
            </p>
            <p>Kezdhetjük?</p>
          </div>
        </div>
      </div>
    </main>
    <footer class="footer">
      <div class="container">
        <div class="footer-button-only">
          <a @click="goNext()" class="button button-blue">Készen állok</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MarketTimer from '../components/MarketTimer.vue';
export default {
  components: { MarketTimer },
  computed: {
    ...mapGetters(["nickname"])
  },
  methods: {
    goNext() {
      this.$store.dispatch("storeGameplayEvent", {
        round: 0,
        event: "start",
      });

      this.$router.push("difficulty");
    },
    roundEnd() {
      this.goNext();
    }
  },
};
</script>

<style>
</style>